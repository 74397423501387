import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import axios from 'axios'
import Login from '../pages/Auth/Login'
import PrivateRoute from '../components/PrivateRoute'
import {
  Organizations,
  NewOrganizationUser,
  NewOrganization,
  EditOrganization,
  ShowOrganization,
  EditOrganizationUser,
} from '../pages/Organizations'
import { Benefits, ShowBenefit, NewBenefit, EditBenefit } from '../pages/Benefits'
import { Cities, NewCity, EditCity, ShowCity } from '../pages/Cities'
import { Members, NewMember, EditMember, ShowMember, SubUsers } from '../pages/Members'
import { Admins, CreateAdmin, EditAdmin, ShowAdmin } from '../pages/Admins'
import { UserBenefitRedemptions } from '../pages/UserBenefitRedemptions'
import { UserLoginHistory } from '../pages/UserLoginHistory'
import { clearAuth } from '../reducer/auth'
import { RouterPath } from './routes'
import ClaimedRedemptions from '../pages/Benefits/ClaimedRedemptions'
import RegisteredRedemptions from '../pages/Benefits/RegisteredRedemptions'
import HomepageManagement from '../pages/HomepageManagement/HomepageManagement'
import ForgotPassword from '../pages/Auth/ForgotPassword'
import ResetPassword from '../pages/Auth/ResetPassword'
import { Leads, ShowLead, EditLead } from '../pages/Leads'
import OrganizationMembershipLists from '../pages/Organizations/OrganizationMembershipLists'
import NewSubOrganization from '../pages/SubOrganizations/NewSubOrganization'
import EditSubOrganization from '../pages/SubOrganizations/EditSubOrganization'

const AdminAppRouter: React.FC = (): JSX.Element => {
  const dispatch = useDispatch()

  axios.interceptors.response.use(
    (response) => {
      return response
    },
    (error) => {
      if (error.response?.status === 401) {
        dispatch(clearAuth())
      }
      console.error(error.response?.data)
      return Promise.reject(error)
    }
  )

  return (
    <BrowserRouter>
      <Switch>
        <PrivateRoute path={RouterPath.MAIN} exact>
          <Organizations />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_ORGANIZATION} exact>
          <NewOrganization />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.ORGANIZATION_VIEW} exact>
          <ShowOrganization />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.ORGANIZATION_MEMBERSHIP_LISTS} exact>
          <OrganizationMembershipLists />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_ORGANIZATION_MEMBER} exact>
          <NewOrganizationUser />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_USER} exact>
          <EditOrganizationUser />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_ORGANIZATION} exact>
          <EditOrganization />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_SUB_ORGANIZATION} exact>
          <EditSubOrganization />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_SUB_ORGANIZATION} exact>
          <NewSubOrganization />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.BENEFITS_VIEW} exact>
          <Benefits />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_BENEFIT} exact>
          <NewBenefit />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_BENEFIT} exact>
          <EditBenefit />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.BENEFIT_VIEW} exact>
          <ShowBenefit />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.BENEFIT_CLAIMED_REDEMPTIONS} exact>
          <ClaimedRedemptions />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.BENEFIT_REGISTERED_REDEMPTIONS} exact>
          <RegisteredRedemptions />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.CITIES_VIEW} exact>
          <Cities />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_CITY} exact>
          <NewCity />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_CITY} exact>
          <EditCity />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.CITY_VIEW} exact>
          <ShowCity />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.MEMBERS_VIEW} exact>
          <Members />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_MEMBER} exact>
          <NewMember />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_MEMBER} exact>
          <EditMember />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.MEMBER_REDEMPTIONS_VIEW} exact>
          <UserBenefitRedemptions />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.MEMBER_VIEW} exact>
          <ShowMember />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.SUB_USERS_VIEW} exact>
          <SubUsers />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.ADMINS_VIEW} exact>
          <Admins />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.NEW_ADMIN} exact>
          <CreateAdmin />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_ADMIN} exact>
          <EditAdmin />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.ADMIN_VIEW} exact>
          <ShowAdmin />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.USER_LOGIN_HISTORY} exact>
          <UserLoginHistory />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.HOME_PAGE_MANAGEMENT} exact>
          <HomepageManagement />
        </PrivateRoute>

        <PrivateRoute path={RouterPath.LEADS_VIEW} exact>
          <Leads />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.SHOW_LEAD} exact>
          <ShowLead />
        </PrivateRoute>
        <PrivateRoute path={RouterPath.EDIT_LEAD} exact>
          <EditLead />
        </PrivateRoute>

        <Route path={RouterPath.LOG_IN}>
          <Login />
        </Route>
        <Route path={RouterPath.FORGOT_PASSWORD}>
          <ForgotPassword />
        </Route>
        <Route path={RouterPath.RESET_PASSWORD}>
          <ResetPassword />
        </Route>
      </Switch>
    </BrowserRouter>
  )
}

export default AdminAppRouter
