export enum RouterPath {
  MAIN = '/panel',
  NEW_ORGANIZATION = '/panel/organizations/new',
  ORGANIZATION_VIEW = '/panel/organizations/:id',
  ORGANIZATION_MEMBERSHIP_LISTS = '/panel/organizations/:id/membership_lists',
  NEW_ORGANIZATION_MEMBER = '/panel/organizations/:id/members/new',
  NEW_SUB_ORGANIZATION = '/panel/organizations/:id/new_sub_organization',
  EDIT_SUB_ORGANIZATION = '/panel/organizations/:id/edit_sub_organization/:sub_id',
  EDIT_USER = '/panel/organizations/:id/members/:userId/edit',
  BENEFITS_VIEW = '/panel/benefits',
  NEW_BENEFIT = '/panel/benefits/new',
  EDIT_BENEFIT = '/panel/benefits/:id/edit',
  BENEFIT_VIEW = '/panel/benefits/:id',
  BENEFIT_CLAIMED_REDEMPTIONS = '/panel/benefits/:id/claimed_redemptions',
  BENEFIT_REGISTERED_REDEMPTIONS = '/panel/benefits/:id/registered_redemptions',
  EDIT_ORGANIZATION = '/panel/organizations/:id/edit',
  CITIES_VIEW = '/panel/cities',
  NEW_CITY = '/panel/cities/new',
  EDIT_CITY = '/panel/cities/:id/edit',
  CITY_VIEW = '/panel/cities/:id',
  MEMBERS_VIEW = '/panel/members',
  NEW_MEMBER = '/panel/members/new',
  EDIT_MEMBER = '/panel/members/:id/edit',
  MEMBER_REDEMPTIONS_VIEW = '/panel/members/:id/redemptions',
  MEMBER_VIEW = '/panel/members/:id',
  SUB_USERS_VIEW = '/panel/members/:id/subusers',
  ADMINS_VIEW = '/panel/admins',
  NEW_ADMIN = '/panel/admins/new',
  EDIT_ADMIN = '/panel/admins/:id/edit',
  ADMIN_VIEW = '/panel/admins/:id',
  USER_LOGIN_HISTORY = '/panel/members/:id/history',
  HOME_PAGE_MANAGEMENT = '/panel/homepage_management',
  LEADS_VIEW = '/panel/leads',
  SHOW_LEAD = '/panel/leads/:id',
  EDIT_LEAD = '/panel/leads/:id/edit',
  LOG_IN = '/panel/login',
  FORGOT_PASSWORD = '/panel/forgot_password',
  RESET_PASSWORD = '/panel/reset_password',
}

export const pathsNeedsEditPermission = [
  RouterPath.NEW_ORGANIZATION,
  RouterPath.NEW_ORGANIZATION_MEMBER,
  RouterPath.EDIT_SUB_ORGANIZATION,
  RouterPath.NEW_SUB_ORGANIZATION,
  RouterPath.EDIT_USER,
  RouterPath.NEW_BENEFIT,
  RouterPath.EDIT_BENEFIT,
  RouterPath.EDIT_ORGANIZATION,
  RouterPath.NEW_CITY,
  RouterPath.EDIT_CITY,
  RouterPath.NEW_MEMBER,
  RouterPath.EDIT_MEMBER,
  RouterPath.NEW_ADMIN,
  RouterPath.EDIT_ADMIN,
]
