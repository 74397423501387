import React, { useCallback, useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { applyParamsToUrlPath, downloadFile } from '../../utils'
import { RegisteredRedemption } from '../../dtos/BenefitDTO'
import DefaultLayout from '../../layouts/DefaultLayout'
import TableWrapper from '../../layouts/TableWrapper'
import useCredentials from '../../hooks/useCredentials'
import BackButton from '../../components/BackButton'
import { usePagination } from '../../hooks/usePagination'

const RegisteredRedemptions: React.FC = (): JSX.Element => {
  const params = useParams<{ id: string }>()
  const { credentials } = useCredentials()
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<string>('')
  const [redemptions, setRedemptions] = useState<Array<RegisteredRedemption>>([])
  const { totalPageCount, setTotalPageCount, currentPageNumber, setPageNumber } = usePagination()

  const loadRedemptions = useCallback(async () => {
    try {
      setError('')
      const res = await axios.get(
        applyParamsToUrlPath({ page: currentPageNumber }, `/api/panel/benefits/${params.id}/redemptions/registered`),
        {
          headers: credentials,
        }
      )
      setRedemptions(res.data.redemptions || [])
      setTotalPageCount(res.data.meta.total_pages)
    } catch (e) {
      setError(e?.message)
    } finally {
      setLoading(false)
    }
  }, [credentials, currentPageNumber, params.id, setTotalPageCount])

  const loadRedemptionsCsv = useCallback(async () => {
    try {
      const res = await axios.get(`/api/panel/benefits/${params.id}/redemptions/export_registered`, {
        headers: credentials,
      })
      downloadFile(res.data, 'registered_redemptions', 'csv')
    } catch (e) {
      setError(e?.message)
    }
  }, [credentials, params.id])

  useEffect(() => {
    loadRedemptions()
  }, [loadRedemptions])

  return (
    <DefaultLayout>
      <div className='mb-4 is-flex is-justify-content-space-between'>
        <BackButton />
        <button onClick={loadRedemptionsCsv} className='button is-link' type='button'>
          Download .csv
        </button>
      </div>
      <TableWrapper
        title='Registered redemptions'
        loading={loading}
        isError={!!error}
        errorMessage={error}
        isEmpty={!redemptions?.length}
        pagination={{
          totalPageCount,
          currentPage: currentPageNumber,
          onChangePage: setPageNumber,
        }}
      >
        <table className='table is-fullwidth'>
          <thead>
            <tr>
              <th>User</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Information</th>
              <th>Registered at</th>
            </tr>
          </thead>
          <tbody>
            {redemptions.map((row) => (
              <tr key={row.id}>
                <td>{row.user_name}</td>
                <td>{row.email}</td>
                <td>{row.phone}</td>
                <td>{row.info}</td>
                <td>{row.registered_at && new Date(row.registered_at).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </DefaultLayout>
  )
}

export default RegisteredRedemptions
